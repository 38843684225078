<template>
  <div>
    <div class="form-card">
      <div class="mb-3">
        <label for="name" class="form-label required">
          الاسم <span>*</span>
        </label>
        <input
          class="form-control "
          :class="{ 'is-invalid': !body.name }"
          required
          id="name"
          v-model="body.name"
        />
      </div>
      <div class="mb-3">
        <label for="countriesId" class="form-label required">
          الدوله الافتراضيه
          <span>*</span>
        </label>

        <select
          class="form-select form-control"
          id="countriesId"
          name="countriesId"
          v-model.number="body.countriesId"
          :class="{ 'is-invalid': isInvalid && !body.countriesId }"
          required
        >
          <option
            v-for="item of countriesList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</option
          >
        </select>
      </div>

      <div class="mb-3">
        <label for="citiesId" class="form-label required">
          المدينه الافتراضيه
          <span>*</span>
        </label>

        <select
          class="form-select form-control"
          id="citiesId"
          name="citiesId"
          v-model.number="body.citiesId"
          :class="{ 'is-invalid': isInvalid && !body.citiesId }"
          required
        >
          <option v-for="item of citiesList" :key="item.id" :value="item.id">{{
            item.name
          }}</option>
        </select>
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil"
          v-tooltip="'تعديل'"
          class="p-ml-2 p-button-info"
          @click="update()"
          :disabled="disabledAdd"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        name: null,
        countriesId: null,
        citiesId: null,
      },
      disabledAdd: false,
      countriesList: [],
      citiesList: [],
    };
  },
  methods: {
    update() {
      if (this.body.name) {
        this.disabledAdd = true;
        this.$http.put(`company`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });

            this.disabledAdd = false;
            // this.$router.push('/admin/company');
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (!this.$checkRoles('companyVeiw')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`countries`).then((res) => {
      this.countriesList = res.data;
    });
    this.$http.get(`company`).then(
      (res) => {
        this.body = res.data;
        this.body.countriesId = res.data.countriesId.id;
        this.body.citiesId = res.data.citiesId.id;
      },
      (err) => {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: err.response.data.message,
          life: 3000,
        });
      },
    );
  },
  watch: {
    'body.countriesId'(val) {
      this.$http.get(`cities?countriesId=${val}`).then((res) => {
        this.citiesList = res.data;
      });
    },
  },
};
</script>
